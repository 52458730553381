import styled, { css } from 'styled-components';
import Datetime from 'react-datetime';

const ChallengeHeading = styled.div`
display: flex;
width: 100%;
background: white;
border-radius:6px;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color: ${({ color }) => color ? color : '#0D4270'};
height:60px;
padding-left:0px;
align-items: center;
margin-bottom: 25px;
`;

const Container = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
.stepsNameHighlight{
  ${'' /* width: 32.1%;  */}
  display: flex;
  max-width:400px;
  height:100%;
  border-radius:6px;
  width:300px;
}
.form{
  ${'' /* width: calc(67.9% - 25px); */}
  margin-left: 25px;
  display: block;
  background: ${({ background}) => background ? "none" : 'white'};
  border-radius:6px;
  margin-bottom:150px;
  width:924px;
  padding:16px;
  .heading{
    width:100%;
    border-bottom: ${({ disable }) => disable ? "none" : '1px solid #005C874D'};
    display: flex;
    height: 70px;
    background: white;
    border-radius: 6px 6px 0 0;
    .step{
      width:40px;
      height:40px;
      background: ${({ stepBackground}) => stepBackground ? stepBackground : '#005C87'};
      display:flex;
      align-items:center;
      justify-content:center;

      font-family: Rubik-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      color:white;
      margin: 15px 25px 15px 0px;
      border-radius:3px;
    }
    .headingName{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color: ${({ color}) => color ? color : '#0D4270'};
      display:flex;
      align-items:center;
    }
  }
  .formBody{
    width:100%;
    display:block;
    ${'' /* padding: ${({ paddingForm}) => paddingForm ? "0px 50px 0px 50px" : '0px 50px 50px 50px'}; */}
    background:white;
    min-height: 330px;
    height: ${({ height}) => height ? height : ''};
    border-radius: 0 0 6px 6px;
    .dateCard{
      width:100%;
      display:flex;
      justify-content: space-between;
      margin-top: 10px;
      .separate{
        font-family: "Rubik-Medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        display:flex;
        align-items:center;
        // padding-top: 40px;
      }
    }
    .checkbox{
      width:100%;
      display:flex;
      .text{
        display:flex;
        margin:auto;
        margin-right:0px;
        align-items:center;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color:rgba(0, 92, 135, 0.3);
      }
    }
    .radioButtonDiv{
      width:100%;
      display:flex;
      justify-content:flex-start;
      align-items:center;flex-direction:row;
      span{  width:auto;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0D4270;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        flex-direction:row;
        margin-right: 50px;
      }
    }
  }
  .taskHeading{
    width:100%;
    display:flex;
    border-bottom: 1px solid rgba(156, 156, 156, 0.4);
    background:white;
    .task{
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color:#0D4270;
      diplay:flex;
      margin:auto;
      margin-left:25px;
      padding: 15px 0px;
    }
    .imageWrapper{
      display:flex;
      margin:auto;
      margin-right:15px;
      >img{
        width:30px;
        height:30px;
        margin-left:10px;
      }
    }
  }
}
`;

const StepsWrapper = styled.div`
display: block;
width: 100%;
padding: 50px 40px;
background:white;
border-radius: 6px;
`;

const Steps = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
.wrapper{
    width:100%;
    display:flex;
.number{
  width:40px;
  height:40px;
  background: ${({ active, activeColor }) => activeColor? '#85C0EA' :active ? "#005C87" : '#005C870D'};
  margin-right: 25px; 

  font-family: ${({ active, activeColor }) => activeColor?'Rubik-Medium': active ? "Rubik-Medium" : 'Rubik-Medium'};
  font-size: 24px;
  line-height: 24px;
  color: ${({ active, activeColor }) => activeColor?'white': active ? "white" : '#005C87'};
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius:3px;
}
.heading{
    font-family: ${({ active,activeColor }) => activeColor ? "Rubik-Medium": active ? "Rubik-Medium" : 'Rubik'};
    font-size: 18px;
    line-height: 24px;
    color: ${({ active,activeColor }) => activeColor ? "#005C87": active ? "#0D4270" : '#005C87'};
    align-items: center;
    display: flex;
}
}
.arrow{
    width:100%;
    display:flex;
    .active{
        width: 40px;
        height:40px;
        display:flex;
        justify-content:center;
        margin:15px 0px;
        >img{
            width: 32px;
            height: 32px;
            ${'' /* transform: rotate(90deg); */}
        }
    }
}
`;

const InputContainer = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  display: flex;
  justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  margin: ${({ margin }) => margin ? 'none' : 'auto'};
  padding-bottom: 25px;
  padding: ${({ padding }) => padding ? padding : '0'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  .radio{
    width:50%;
    display:flex;
    >span{
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color:#0D4270;
      width: 100%;
      display: flex;
    }
  }
  .PhoneInput{
    margin-left: -35px;
      .PhoneInputInput{
        padding: 12px;
      }
  }
  .dropdown{
  >option {
  
  .open{
  &::placeholder {
    font-size: 18px;
    color: rgba(0, 92, 135, 0.3);
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
   
  } } @media (max-width: 1200px) {
    width: 100%;
  }
  }}

  @media (max-width: 600px) {
    width: 100%;
    
  }


  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .radioButtonClassCategories{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    span{
      display: flex;
      flex: 0 0 270px;
      margin: 5px;
      color: #005c87;
    }
  }
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: #005C87;
  color: ${({ color }) => color ? color : '#005c87'};
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  margin-top:25px;
  margin-bottom:15px;
  display: flex;
  width: ${({ width }) => width ? width : '100%'};
  >span{
    font-size: 18px;
    color:#F4AAA9;
  }
  .imp{
    font-size: 18px;
    color:#F4AAA9;
  }
  .charCount{
    display:flex;
    margin:auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 92, 135, 0.3);
    margin-right:0px;
  }
  .radio{  width:auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
      display:flex;
      justify-content:flex-start;
      align-items:center;
      flex-direction:row;
      margin: auto;
    margin-right: 0px;
    }
`;

const StyledInputV2 = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 48px;
  font-family: rubik-regular;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color:${({ color, disabled }) => color ? color : disabled ? 'rgba(0, 92, 135, 0.6)' : '#005C87'};
  ${'' /* border:${({ border }) => border ? border : 'rgba(0, 122, 177, 0.30)'}; */}
  background-color:${({ bgColor }) => bgColor ? bgColor : 'white'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  border:1px solid rgba(0, 122, 177, 0.30);

  &:active{
    border:${({ border }) => border ? border : '1px solid rgba(0, 92, 135, 0.3)'};
  }
  &::placeholder {
    font-size: 16px;
    color: rgba(0, 92, 135, 0.3);
    color:${({ pColor }) => pColor ? pColor : 'rgba(0, 92, 135, 0.3)'};
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus, &:active {
    outline: none;
  }
  
`;

const UploadPhotoContainer = styled.div`
width:100%;
height:auto;
${'' /* display: flex; */}
  justify-content: flex-start;
  align-items:center;
  margin-top:15px;
  .uploadImage{
    height: 188px;
    width: 300px;
    background-color:${({ bgColor }) => bgColor ? bgColor : 'white'};
    border:1px solid rgba(0, 122, 177, 0.30);
    border-radius: 6px;
    display: flex;
    .uploadButton{
      background: #69C2FF;
      border-radius: 3px;
      font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
    }
  }
   .innerDivImageContainer{
    width:100%;
    height:100%;
   position:relative;
   display: flex;
  justify-content: flex-start;
  align-items:normal;
   }

   .innerDivImageContainer img{
    height: 188px;
    width: 300px;
  object-fit:cover;
border-radius:6px;
border:1px solid rgba(0, 122, 177, 0.30);
>img{
  width:100%;
  height:100%;
}

}

.innerDivImageContainer  .infoBtn{
width:24px;
heigth:24px;
font-size:15px;
color:#fff;
border-radius:50%;
background-color:rgba(0, 92, 135, 0.3)}

@media (max-width: 600px) {
  width:100%;
   flex-direction: column;
   justify-content:center;
   .innerDivImageContainer {
    width:125px;
  height:125px;
  border-radius:50%;
  overflow:hidden;
   }
   .innerDivImageContainer  .infoBtn{display:none}
   .innerDivImageContainer  img{
  width:125px;
  height:125px;
  border-radius:50%
}
}
`

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  margin-top: ${({ margin }) => margin ? margin : '10px'};
  font-family: "Rubik-Medium";
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({ imageReload }) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}
`;

const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:126px;
height:40px;
background-color: ${({ bgColor }) => bgColor ? "#85C0EA" : '#69C2FF'};
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
  padding: 10px;
  margin-bottom: 0;
  margin:auto;
  > i {
font-family:rubik-medium;
font-style:normal;
font-weight:400;
font-size:14px;
line-height:20px;
color:#fff; 
text-transform: capitalize;
text-align: center;
  }
  @media (max-width: 600px) {
    position: absolute;
background-color:#c4c4c4;
margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }

  .middle-section{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    flex-direction:column;
  }

  .title{
    color:  #007AB1;
text-align: center;
font-family: Rubik-Medium;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
margin-top:12px;
text-transform: capitalize;

  }

  .sub-title{
    color:  #007AB1;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 20px;
width:100%;
display:flex;
justify-content:center;
text-transform: lowercase;

  }

  .flyer-name{
    color:  #76AB78;
text-align: center;
font-family: Rubik-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
  }
`;

const RemoveImage = styled.div`
display: flex;
justify-content: center;
color:white;
align-items:center;
width:164px;
height:50px;
background-color: ${({ bgColor }) => bgColor ? "#F4AAA9" : '#FD7175'};
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
padding: 0;
margin-bottom: 0;
font-family:rubik-medium;
cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
@media (max-width: 600px) {
  position: absolute;
background-color:#c4c4c4;

margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
  font-size: 10px;
  >i{
    font-size:10px;
  }
}
`;

const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Medium";
  padding: ${({ padding }) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  border: 1px solid rgba(156, 156, 156, 1);
  width: 100%;
  resize: none;
  color: #0D4270;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({ height }) => height ? height : '165px'};
  background: rgba(156, 156, 156, 0.05);
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: rgba(0, 92, 135, 0.3);
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: rgba(0, 92, 135, 0.3);
  }

  ::-ms-input-placeholder {
    color: rgba(0, 92, 135, 0.3);
  }
`;

const ButtonContainer = styled.div`
margin-top: 10px;
width: calc(100% - 80px);
background:white;
display: flex;
align-items: center;
text-align: center;
padding:10px 0px;
// margin-bottom:100px;
bottom:0;
position: fixed;
z-index:10;
box-shadow: 0px -15px 14px -10px #0000001A;
.wrapper{
  width: 100%;
  max-width: 1246px;
  margin: auto;
  display: flex;
}
@media (max-width: 600px) {
  width: calc(100% + 32px);
  margin: 0;
  margin-left: -16px;
  justify-content:center; 
  position:unset;
 flex-direction: column-reverse;
  flex-wrap:wrap;
  padding:25px 0;
  border-top:none;
  box-shadow:unset;
  background: #f3f6f9;
}
`;

const Button = styled.button`
  margin:auto;
  width: 200px;
  width: ${({ width }) => width ? width : '200px'};
  background: ${({ color, disabled }) =>  disabled ? "rgba(0, 122, 177, 0.30)" :color ? color: '#007AB1'};
  border: ${({ border, disabled }) => disabled ? "rgba(0, 122, 177, 0.30)" :border ? `1px solid ${border}`: '1px solid #007AB1'};
  float: right;
  padding: 10px;
  font-size: 18px;
  color: ${({ textColor }) =>textColor?textColor:'white'};
  margin-left: ${({ marginLeft, marginAuto }) => marginAuto?"auto":marginLeft ? '0px' : '0px'};
  margin-right: ${({ marginRight }) => marginRight ? '15px' : '0px'};
  border-radius: 3px;
  font-family:"Rubik-Medium";
  &:first-child {
  font-size: 18px;
  }
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  
  ${({ color }) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
  @media (max-width: 600px) {
    width: 90%;
    margin-top:5px;
   background-color:${({ color }) => color && "transparent"}
   border:${({ color }) => color && "none"}
  }
  display: ${ ({isOnDemandEvent}) => isOnDemandEvent && "flex"};
  flex-direction: ${ ({isOnDemandEvent}) => isOnDemandEvent && "row"};
  justify-content: ${ ({isOnDemandEvent}) => isOnDemandEvent && "center"};
  align-items: ${ ({isOnDemandEvent}) => isOnDemandEvent && "center"};
`;

const NextButton =styled(Button)`
  margin: 0px;
  width: 200px;
  height:50px;
  font-family: 'Rubik';
  margin:8px 0;
  margin: auto;
  margin-right: 15px;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
background: #69C2FF;
border-radius: 3px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;
color: #FFFFFF;
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  @media (max-width: 600px) {
    width: 90%;
  
  }
`;

const AddTaskButtonWrapper = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
padding-bottom:15px;
`;

const AddTaskButton = styled.div`
height: 40px;
min-width: 104px;
border-radius: 3px;
color: ${({ active, addTask }) => active? 'white':addTask?"#69c2ff":'rgba(0, 92, 135, 0.3)'};
background: ${({active }) => active? '#69c2ff':'white'};
border: ${({active,addTask }) => active? 'none':addTask?'1px solid #69c2ff':'1px solid rgba(0, 92, 135, 0.3)'};
cursor:pointer;
font-size: ${({active }) => active? '16px':'18px'};
font-family: ${({active }) => active? 'Rubik-Medium':'Rubik-Medium'};
display:flex;
align-items:center;
justify-content:center;
margin:15px 15px 0px 0px;
`;

const CustomRadioButton = styled.div`
margin-right:10px;
width:24px;
height:24px;
width: ${({width }) => width? width:'24px'};
height: ${({width }) => width? width:'24px'};
border:1px solid rgba(0, 92, 135, 0.3);
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : "pointer"};
>div{
  width:16px;
  height:16px;
  border-radius: 50%;
        background-image:url(https://ddf0gygzuiev4.cloudfront.net/images/newRadioCheck.svg);
        background-repeat:no-repeat;
        background-position:center;
        box-shadow:none;
        border:1px solid #005C87;
}
.colorActive{
  width:14px;
  height:14px;
  border-radius:50%;
  background:#85C0EA;

}
>img{
  width:16px;
  height:16px;
  width: ${({width }) => width? "20px":'16px'};
  height: ${({width }) => width? "20px":'16px'};
}
`;

const FieldTitleNote = styled.div`
  font-size: 16px;
  margin-bottom : 25px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #FD7175;
  font-family: 'Rubik';
  width:100%;
  background: rgba(156, 156, 156, .04);
  background: ${({background }) => background? 'rgba(156, 156, 156, .04)':'none'};
  padding:3px 0px 3px 10px;
  display:flex;
  align-items:center;
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ imageArrow }) => imageArrow ? "110px" : " 105px"};
position: absolute;
right: 15px;
display:block;
z-index:5;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ background }) => background ? background : '#69c2ff'};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 39px;
  height: 20px;
  margin-bottom: 0;

  @media (max-width: 500px) {
    left: calc(100% - 60px);
  }
  
  > input {
    display: none;
  }
  
  > input:checked + .slider {
    background-color: #81d487;
  }

  > input:focus + .slider {
    box-shadow: 0 0 1px #81d487;
  }
  
  > input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

const EditorContainer = styled.div`
width: 100%;
margin-top: 0px;
`;

const Layout = styled.div`
padding: 25px;
width: 100%;
font-family: Rubik-Regular;
margin-bottom: 0px;
display: flex;
.main{
  width: 100%;
  max-width: 1248px;
  margin: auto;
}
`;

const StyledLabelV2 = styled.div`
  display: flex;
  width: ${({ width }) => width ? width : '100%'};
  ${'' /* margin-top:25px; */}
  margin-bottom:15px;

  .name{
    width:auto;
    display: block;
  font-size: 16px;
  color: #005C87;
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  pointer-events: none;
  >span{
    font-size: 18px;
    color:#F4AAA9;
  }
  }

  .radio{  
    width:50%;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
    margin: auto;
    margin-right: 0px;
    margin-right: ${({ marginRight }) => marginRight ? marginRight : '0px'};
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : ''};
    align-items: center;
    justify-content: ${({ justify }) => justify ? 'flex-start' : 'flex-end'};
    }
`;

const StyledDateTime = styled(Datetime)`
width:100%;
max-width:585px;
cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    padding: 15px;
    width:100%;
    max-width:585px;
    border-radius:6px;
    font-size:${({ challenge }) => challenge ? "16px" : '18px'};
    height: 50px;
    color:${({ color }) => color ? color : 'rgb(153,153,153)'};
    border:${({ border }) => border ? border : '1px solid #cdcbcb'};
    position: relative;
    background:${({ bgColor }) => bgColor ? bgColor : 'white !important'};
    cursor: pointer;
    font-family:rubik-medium;
    ${'' /* color: rgba(0,47,71,1); */}
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    color: ${({ disabled, color }) => disabled ? 'rgba(0, 92, 135, 0.60) !important' : color ? color : 'rgb(0, 47, 71) !important'};

    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
     
    }
  }
  > input::placeholder{
font-family:rubik;
color:#005C8799 !important;
  }
  
  .rdtPicker {
    width: 100%;
  }
  .rdtPicker{
    display:${({ displayNone }) => displayNone && 'none !important'};
  }
  .custom-input input::placeholder{
    color:red !important;
  }
`;

const DownArrow = styled.div`
 
  float: left;
  position: absolute;
  top: 30px;
  right: 5%;
  cursor: pointer;

`;

const Locationwrapper = styled.div`
display: flex;
width: 100%;
margin-top:15px;
border-radius:6px;
max-height:40vh;
overflow-y:auto;
${'' /* border: ${({ border, borderDot }) => borderDot?"1px dashed rgba(0, 92, 135, 0.3)": border ? 'none' : '1px solid #005C8799'}; */}
${'' /* background: ${({ background }) => background ? background : 'linear-gradient(0deg, rgba(156, 156, 156, 0.05), rgba(156, 156, 156, 0.05))'}; */}
${'' /* padding: ${({ padding }) => padding ? padding : '15px 15px 0px 25px'}; */}
margin-top: ${({ marginTop }) => marginTop ? marginTop : '15px'};
flex-wrap:wrap;
.noData{
width:100%;  
display:flex;
justify-content:center;  
font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0px;
text-align: center;
color: #005C87;
}
.checkBoxWidth{
  width:33.33%;
  display:flex;
  align-items:center;
  margin-bottom:15px;
  span{  width:auto;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0D4270;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
  }
}
`;

const Heading = styled.div`
display: flex;
width: 100%;
margin-top:15px;
.name{
font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 20px;
color:#005C87;
display:flex;
margin:auto;
margin-left:0px;
.astric{  
  font-size: 18px;
  color: #F4AAA9;
}
.imp{
  font-size: 18px;
  color:#F4AAA9;
}
}
.selectAllRadio{
  display:flex;
  align-items:center;
  margin:auto;
  margin-right:0px;
  span{  width:auto;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #005C87;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;
  }
}
`;

const InputContainerV2 = styled.div`
  display: inline-block;
  width: ${({ width }) => width ? width : '100%'};
  margin-right: 10px;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop && marginTop };
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  i {
    position: absolute;
    right: 10px;
    top: 17px;
    color: #999999;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  > div {
    width: 100%;
  }

  svg {
    top: 0;
    right: 0px;
    height: 42px;
    width: 15px;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  .rdtPicker {
    width: 100% !important;
    min-width: ${({ event }) => event ? '150px !important' : '300px'};
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;

const FlayerMain = styled.div`
  width:100%;
  background: ${({ background }) => background ? background : "rgba(156, 156, 156, 0.05)" };
  border: ${({ border }) => border ? border : "1px solid rgba(156, 156, 156, 0.4)" };
  border-radius: 6px;
  display:flex;
  height:92px;
  margin: ${({ margin }) => margin && margin };
  .uploadImage{
    width:60px;
    height:60px;
    margin:auto;
    margin-left:15px;
    margin-right:0px;
    display:flex;
    background: #edf1ef;
    border-radius:3px;
    >img{
      margin:auto;
      display:flex;
      width:36px;
      height:36px;
    }
  }
  .fileMain{
    display:flex;
    width:auto;
    margin:auto;
    width:calc(100% - 210px);
    padding-right:25px;
    display:block;
    padding-left:15px;    

  .fileName{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color:${({ color }) => color ? color : "#69c2ff" };
    align-items:center;
    display: flex;
   
  }
  .progressBar{
    width:100%;
    height:16px;
    background: rgb(156, 156, 156, 0.2);
    border-radius: 12px;
    margin-top:5px;
    .progressValue{
    width:${({ width }) => width ?`${width}%` : '0'};
    height:16px;
    background: ${({ pBarBgColor }) => pBarBgColor ? pBarBgColor : "linear-gradient(227.68deg, #00F0FF 15.32%, #3586FF 85.04%)" };
    border-radius: 12px;
    }
  }
}
  .button{
    width: 120px;
    height: 60px;
    background: ${({ active }) => active ? 'transparent' : '#005c87'};
    border-radius:6px;
    border: ${({ active }) => active ? '1px solid #649bb3' : 'none'};
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${({ active }) => active ? '#649bb3' : 'white'};
    display:flex;
    text-align:center;
    align-items:center;
    margin:auto;
    margin-right:15px;
    justify-content: center;
    cursor:pointer;
    >input{
      opacity: 0;
      // z-index: -1;
      position: absolute;
      bottom: ;
      width:100px;
      height:60px;
      cursor:pointer;
    }
  }
`;

const SearchDropDown = styled.div`
 width: ${({ width }) => width ? width : '100%'};
 display:flex;
 height: ${({ height }) => height ? height : '50px'};
 background: rgba(247, 245, 235, 0.5);
 border: 1px solid rgba(0, 92, 135, 0.3);
 border-radius: 6px;
 height: 100%;
 .css-b62m3t-container{
    width: 100%;
    .menu-item__control {
      height: 100%;
      .menu-item__indicators{
        width: ${({ indicatorWidth }) => indicatorWidth ? indicatorWidth : '0px'};
      }
      .menu-item__indicators{
        width: ${ ({ isReward }) => isReward && '15px'};
        span{
          display: ${ ({ isReward }) => isReward && 'none'};
        }
      }

      .menu-item__value-container{
        padding: ${({ valueContainerP }) => valueContainerP ? valueContainerP : '0px 20px'};
        .menu-item__placeholder{
          font-family: rubik;
          color: rgb(0, 92, 135);
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
 }
 .menu-item__menu{
    font-family: ${({isReportDropdown}) => isReportDropdown ? 'Rubik-Medium' : 'Rubik-Regular'};
    color: ${({isReportDropdown}) => isReportDropdown && "rgba(0, 92, 135, 0.6)"};
    font-size: ${({isReportDropdown}) => isReportDropdown && "12px"};
    line-height: ${({isReportDropdown}) => isReportDropdown && "20px"};
 }
 .menu-item__option, .menu-item__option--is-focused{
    border: ${({isReportDropdown}) => isReportDropdown && "5px solid white" };
    color: ${({isReportDropdown}) => isReportDropdown && "rgba(0, 92, 135, 1)"};    
 }
 .menu-item__option{
    background-color: ${({isReportDropdown}) => isReportDropdown && "#fff"};
    color: #007AB1;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
border-bottom:1px solid rgba(0, 92, 135, 0.10);
border-spacing:10px;
padding:10px !important;
margin:0px 12px;
 }

 .css-tr4s17-option{
  background:rgba(0, 92, 135, 0.05);
 }

 .css-1dimb5e-singleValue{
  color: #005C87;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;

 }
 .menu-item__menu{
  z-index:10;
 }

 .menu-item__input{
  color: #005C87 !important;
font-family: Rubik-Regular !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 20px !important;

 }
 .css-qbdosj-Input{
  color: #005C87;
font-family: Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
 }
 .menu-item__option--is-focused{
  background-color: ${({isReportDropdown}) => isReportDropdown && "rgba(0, 92, 135, 0.05)"};
 }
 .menu-item__option:hover{
    border-radius: ${({isReportDropdown}) => isReportDropdown && "3px"};
    background-color: ${({isReportDropdown}) => isReportDropdown && "rgba(0, 92, 135, 0.05)"};
    border: ${({isReportDropdown}) => isReportDropdown && "5px solid white" };
    font-family: ${({isReportDropdown}) => isReportDropdown && 'Rubik-Medium'};
    color: ${({isReportDropdown}) => isReportDropdown && "rgba(0, 92, 135, 1)"};
    font-size: ${({isReportDropdown}) => isReportDropdown && "12px"};
 }
.css-1pcexqc-container{
  width:100%;
  height: ${({ height }) => height ? height : '50px'};
  .css-1os4hh5-control {
    width:100%;
    height: ${({ height }) => height ? height : '50px'};
    border:${({ border }) => border ? border : '1px solid #cdcbcb'} ;
  }
}
.css-1hwfws3{
  width:100%;
  height: ${({ height }) => height ? height : '50px'};
  padding: ${({ padding }) => padding ? padding : '0px 20px 0px 20px'};
}
.css-bgvzuu-indicatorSeparator{
  display:none;
}
.css-151xaom-placeholder{
  font-family: ${({ pfont }) => pfont ? pfont : 'rubik'};
  color: ${({ pcolor }) => pcolor ? pcolor : 'rgba(0, 92, 135, 0.3)'};
  font-size: 16px;
  line-height: 20px;
}
.menu-item__placeholder {
  font-family: ${({dropdownTextFont}) => dropdownTextFont ? dropdownTextFont : ''};
}
.css-dvua67-singleValue{
  font-family: rubik-medium;
  font-weight: 500;
  /* color: #0D4270; */
  color: ${({ color }) => color ? color : '#005c87'} ;
  font-size: 16px;
  line-height: 20px;
}
${'' /* .css-4p5fv3-control{
  border: ${({ border }) => border ? border : '1px solid #cdcbcb'};
  border-color:${({ borderColor }) => borderColor ? borderColor : '1px solid #cdcbcb'}#cdcbcb;
  background: ${({ background }) => background ? background : 'white'};
}
.css-4p5fv3-control:hover{
  border: ${({ border }) => border ? border : '1px solid #cdcbcb'};
  border-color:${({ borderColor }) => borderColor ? borderColor : '#cdcbcb'};
  background: ${({ background }) => background ? background : 'white'};
} */}
.menu-item__control{
  background-color: white;
  border: 0px !important;
}
${'' /* .menu-item__control--menu-is-open{
  border:${({ border }) => border ? border : '1px solid #cdcbcb'};
  border-color:${({ borderColor }) => borderColor ? borderColor : '#cdcbcb'};
} */}
.menu-item__indicators{
  margin-left: ${({isReward}) => isReward && '20px'};
  right: ${({ right }) => right ? right : '15px'};
  position:relative;
  >svg{
    right: ${({ right }) => right ? right : '15px'};
    transform: ${({ transform }) => transform ? transform : 'rotate(90deg)'};
  }
}
.css-1thkkgx-indicatorContainer{
  >svg{
    right: ${({ right }) => right ? right : '15px'};
  }
}
`;

const RemoveImageV2 = styled.div`
position:relative;

.main-div{
  position:absolute;
  right:-10px;
  top:-10px;
}

.icon-div{
  background:#007AB1;
  padding:0 8px;
  border-radius:20px;
  width:20px;
  height:20px;
  display:flex;
  justify-content:center;
  align-items:center; 
  cursor:pointer;
}

.sub-icon-div{
  display:flex;
  justify-content:center;
  align-items:center;
}
`

const CircleNew = styled.div`
  width: ${({width}) => width ? width : '136px'};
  height: ${({height}) => height ? height : '136px'};
  border-radius: 50%;
  background: white;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
margin-top:5px;
`;

const CircularBar = styled.div`
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: rgba(0, 92, 135, 0.10);
}
.circle-underline{
  width:85px;
  height:85px;
  border:1px solid;
}
.circle-progress {
  stroke: ${({stroke}) => stroke ? stroke : '#F4AAA9'};
  stroke-linecap: round;
}

.circle-text {
  font-family: Rubik-Regular;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  fill:${({stroke}) => stroke ? stroke : '#F4AAA9'}
}

.points-earned{
  font-family: Rubik;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: center;
  fill: rgba(0, 92, 135, 0.6);
}
`;

const MUICalendarWrapper = styled.div`
display:flex;
justify-content:space-between;

.MuiInputLabel-root {
  color: ${({ color }) => color ? color : '#005C87 !important'};

}

 ${'' /* MuiOutlinedInput-input MuiInputBase-inputAdornedEnd */}

.MuiInputBase-input{
  color: ${({ color }) => color ? color : '#005C87 !important'};

}

>label{
  color: ${({ color }) => color ? color : '#005C87 !important'};
}

>fieldset{
    border-color:rgba(0, 92, 135, 0.3) !important;

  }

.MuiOutlinedInput-notchedOutline {
  border-color:rgba(0, 92, 135, 0.3) !important;

  >fieldset{
    border-color:rgba(0, 92, 135, 0.3) !important;

  }

}

.Mui-disabled{
  -webkit-text-fill-color:rgba(0, 92, 135, 0.60) !important;
}
`

const InitiativeIcon = styled.div`
width: 290px;
height: 182px;
flex-shrink: 0;
border-radius:6px;
${'' /* background:#F4AAA9; */}
background:${({background}) => background ? background : '#F4AAA9'}
display:flex;
align-items:center;
justify-content:center;

.image{
  width: 290px;
height: 182px;
display:flex;
align-items:center;
justify-content:center;

}
`


export {InitiativeIcon,ChallengeHeading, Container, StepsWrapper, Steps, InputContainer, StyledInputV2, StyledLabel, UploadPhotoContainer, RemoveImage, InputImage, CustomTextArea, ButtonContainer, Button, NextButton, AddTaskButtonWrapper, AddTaskButton, CustomRadioButton, FieldTitleNote, DropdownArrow, CustomLabel, Slider, EditorContainer, Layout, StyledLabelV2, StyledDateTime, DownArrow, Locationwrapper, Heading, InputContainerV2, FlayerMain, SearchDropDown, RemoveImageV2, CircularBar, CircleNew, MUICalendarWrapper }